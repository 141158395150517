<!-- 客户管理 -- 往届生续费 -- 列表 -->
<template>
	<div>
		<list-template
			:current-page="page"
			:is-check-input-box="false"
			:loading="loading"
			:search-config="searchConfig"
			:table-config="tableConfig"
			:table-data="tableData"
			:total="total"
			has-add
			@onAdd="$router.push('./add')"
			@onChangePage="handleCurrentChange"
			@onHandle="handleEdit"
			@onSearch="onSearch"
		></list-template>
	</div>
</template>

<script>
import {mapState} from "vuex";
import TableTemplate from "@/components/table/TableTemplate.vue";

export default {
	_config: {"route": {"path": "list", "meta": {"title": "列表", "keepAlive": true}}},
	components: {TableTemplate},
	data() {
		return {
			loading: true,
			search: {},
			dialogLoading: false,
			searchConfig: [
				{
					prop: "name",
					placeholder: "搜索组合名称",
				},
			],
			tableData: [],
			//总条数
			total: 0,
			pageCount: 0,
			tableConfig: [
				{prop: "name", label: "组合名称"},
				{prop: "price", label: "总金额"},
				{prop: "number", label: "收费项目数"},
				{prop: "creator", label: "创建人"},
				{prop: "created_at", label: "创建时间"},
				{
					prop: "handle",
					label: "操作",
					width: "120px",
					handle: true,
					render(row) {
						return ["查看"];
					},
				},
			],
		};
	},
	created() {
		this.$store.commit("setPage", 1);
		this.getData();
	},
	activated() {
		this.getData();
	},
	computed: {
		...mapState(["page"]),
	},
	methods: {
		getData() {
			this.loading = true;
			let params = {page: this.page};
			params = Object.assign(params, this.search || {});
			this.$_register.get("/api/finance/combination/list", {
				params,
			}).then(res => {
				this.tableData = res.data.data.list;
				this.total = res.data.data.page.total;
			}).finally(() => this.loading = false);
		},

		onSearch(val) {
			this.$store.commit("setPage", 1);
			this.search = val;
			this.getData();
		},

		handleEdit(row, text) {
			this.$router.push({
				path: "./look",
				query: {
					id: row.id,
				},
			});
		},
		// 当前页改变触发
		handleCurrentChange(val) {
			//当前页赋值给currentPage
			this.$store.commit("setPage", val);
			//拉取数据
			this.getData();
		},
	},
};
</script>

<style lang="scss" scoped>
</style>
